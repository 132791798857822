import React, { Component } from "react";

import styles from "./assets/index.module.less";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import { games } from "../../games/games";
import { common } from "../../games/common";
import DetailAd from "../../components/detailAd";
import InterstitialAd from "../../components/interstitialAd";

class Detail extends Component {
  state = {};
  setValue(val) {}
  render() {
    const { id } = this.props.match.params;
    const game = games.find((item) => item.id === Number(id));
    // console.log("index===========")
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles["box_1"]} flex-col`}>
          <div className={`${styles["block_1"]} flex-col`}>
            <div className={`${styles["box_2"]} flex-col`}>
              <Nav />
              <div className={`${styles["text-wrapper_1"]} flex-col`}>
                <span className={`${styles["text_2"]}`}>advertisement</span>
                {/*<Nav />*/}
                {/*<DetailAd />*/}
              </div>
              <div className={`${styles["block_3"]} flex-row justify-between`}>
                <div className={`${styles["block_4"]} flex-col`}>
                  <img src={`/${game.icon}`} alt="" />
                  {/*<InterstitialAd />*/}
                </div>
                <div className={`${styles["block_5"]} flex-col`}>
                  <span className={`${styles["text_3"]}`}>{game.name}</span>
                  <div className={`${styles["text-wrapper_2"]} flex-col`}>
                    <span className={`${styles["text_4"]}`}>
                      {game.category}
                    </span>
                  </div>
                  <div className={`${styles["section_1"]} flex-row`}>
                    <img
                      className={`${styles["image_3"]}`}
                      src={require("./assets/img/157a70a92237641ee2f15aa1347bf0da.png")}
                      alt=""
                    />
                    <span className={`${styles["text_5"]}`}>{game.score}</span>
                    <span className={`${styles["text_6"]}`}>
                      102.36k&nbsp;Played
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`${styles["text-wrapper_3"]} ${styles["errtips"]} flex-col`}
              >
                <span className={`${styles["text_7"]}`} onClick={() => {
                  this.props.history.push(`/game/${game.name}`);
                }}>PLAY&nbsp;NOW</span>
              </div>
            </div>
            <div className={`${styles["text-wrapper_4"]} flex-col`}>
              <span className={`${styles["text_8"]}`}>DESCRIPTION&nbsp;:</span>
              <span className={`${styles["paragraph_1"]}`}>
                {game.description}
              </span>
            </div>
            <div className={`${styles["box_3"]} flex-col`}>
              <span className={`${styles["text_9"]}`}>
                RECOMMEND&nbsp;FOR&nbsp;YOU
              </span>
              <div className={`${styles["block_6"]} flex-row`}>
                {common.getRandomElements(games,20).map((item) => {
                  return (
                    <div
                      className={`${styles["block_7"]} flex-col`}
                      key={item.id}
                      onClick={() => {
                        this.props.history.push(`/detail/${item.id}`);
                      }}
                    >
                      <img src={`/${item.icon}`} alt="" />
                    </div>
                  );
                })}
              </div>
              <div className={`${styles["text-wrapper_5"]} flex-col`}>
                <span className={`${styles["text_10"]}`}>advertisement</span>
              </div>
              <Footer/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Detail;
